<template>
  <div class="identification">
    <div v-if="identify.title" class="status-tip">
      <el-alert :title="identify.title" :type="identify.type" :closable="false">
      </el-alert>
    </div>
    <div class="content">
      <el-form ref="ruleForm" label-width="200px" :model="formData" :rules="rules">
        <div class="panel">
          <div class="title">企业信息</div>
          <div class="col-box">
            <el-form-item
              label="企业名称"
              prop="tenantName">
              <el-select
                v-model.trim="formData.tenantName"
                value-key="id"
                @change="selectCompany"
                @focus="focusCompany"
                filterable
                remote
                clearable
                allow-create
                default-first-option
                :remote-method="remoteMethod"
                :loading="loading"
                placeholder="请选择企业"
                :disabled="disabled"
                style="width: 300px;">
                <el-option
                  v-for="item in options"
                  :key="item.id"
                  :label="item.name"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="统一社会信用代码"
              prop="certCode">
              <el-input
                v-model.trim="formData.certCode"
                :readonly="disabled"
                placeholder="请输入统一社会信用代码"
                style="width: 300px;">
              </el-input>
            </el-form-item>
          </div>
          <div class="col-box" >
            <el-form-item
              label="经营地区"
              prop="businessAreaList">
              <area-cascader-multiple
                :readonly="disabled"
                :areaLists="formData.businessAreaList"
                @updateBusinessArea="changeBusinessArea"
                style="width: 800px;"
              />
            </el-form-item>
          </div>
          <div class="col-box">
            <el-form-item
              label="营业执照"
              prop="businessLicenses">
              <!-- <div v-if="!disabled" class="update">
                <img :src="require('@/assets/imgs/workbench/icon_update.svg')" />
                上传
              </div> -->
              <Upload
                v-if="!disabled"
                v-model="fileLists"
                label="营业执照"
                type="single"
                buttonText="上传"
                accept="image/png,image/jpeg,image/jpg,application/pdf"
                @success="handleFileLists"
                @remove="handleRemove"
                :maxSize="5"
                size="origin"
                :limit="1"/>
              <!-- <div v-if="!disabled" class="tip">上传有效期内的营业执照，支持 JPG、JPEG、PNG、BMP、PDF 格式，大小不超过 5 MB</div> -->
              <div v-else v-for="(file,index) in formData.businessLicenses" :key="'file-1-'+index" @click="scan(file.businessLicenseUrl)" class="file">
                <div class="left">{{ '营业执照 '+file.businessLicenseName }}</div>
                <div class="right">
                  <img v-if="!disabled" :src="require('@/assets/imgs/workbench/icon_selected.svg')" />
                  <img v-if="!disabled" @click="deleteFile(index, 'businessLicenses')" :src="require('@/assets/imgs/workbench/icon_delete.svg')" />
                </div>
              </div>
            </el-form-item>
          </div>
        </div>
        <div class="panel">
          <div class="title">平台管理员信息</div>
          <div class="col-box">
            <el-form-item
              label="平台管理员姓名"
              prop="name">
              <el-input
                v-model.trim="formData.name"
                :readonly="disabled"
                placeholder="请输入真实姓名"
                style="width: 300px;">
              </el-input>
            </el-form-item>
            <el-form-item
              label="平台管理员身份证号"
              prop="idCard">
              <el-input
                v-model.trim="formData.idCard"
                :readonly="disabled"
                placeholder="请输入身份证号"
                style="width: 300px;">
              </el-input>
            </el-form-item>
          </div>
          <div class="col-box">
            <el-form-item
              label="平台管理员手机号"
              prop="phoneNumber">
              <el-input
                v-model.trim="formData.phoneNumber"
                :readonly="disabled"
                placeholder="请输入手机号"
                style="width: 300px;">
              </el-input>
            </el-form-item>
            <el-form-item
              v-if="!disabled"
              label="验证码"
              prop="code">
              <el-input
                v-model.trim="formData.code"
                :readonly="disabled"
                placeholder="请输入验证码"
                style="width: 300px;">
                <div slot="append">
                  <span v-if="count === 0" @click="getVericode" class="getCode">{{ again ? '重新' : '' }}获取验证码</span>
                  <span v-else class="getCode">{{ count }}s重发</span>
                </div>
              </el-input>
            </el-form-item>
          </div>
        </div>
        <div class="panel">
          <div class="title">企业公函
            <span v-if="!disabled" class="tip-1">企业信息和平台管理员信息填写完成后可点击<b @click="saveImg('mycanvas')">下载企业公函</b>，加盖公章后上传，如有已盖章公函图片，可直接上传</span>
          </div>
          <el-form-item
            label="企业公函"
            prop="certificationOfficials">
            <!-- <div v-if="!disabled" class="update">
              <img :src="require('@/assets/imgs/workbench/icon_update.svg')" />
              上传
            </div> -->
            <Upload
              v-if="!disabled"
              v-model="fileLists2"
              label="认证公函"
              type="single"
              buttonText="上传"
              accept="image/png,image/jpeg,image/jpg,application/pdf"
              @success="handleFileLists2"
              @remove="handleRemove2"
              :maxSize="10"
              size="origin"
              :limit="10"/>
            <!-- <div v-if="!disabled" class="tip">上传有效期内的营业执照，支持 JPG、JPEG、PNG、BMP、PDF 格式，大小不超过 10 MB，数量不超过10</div> -->
            <div v-else v-for="(file,index) in formData.certificationOfficials" :key="'file-2-'+index" @click="scan(file.certificationOfficialUrl)" class="file">
              <div class="left">{{ '企业公函 '+file.certificationOfficialName }}</div>
              <div class="right">
                <img v-if="!disabled" :src="require('@/assets/imgs/workbench/icon_selected.svg')" />
                <img v-if="!disabled" @click="deleteFile(index, 'certificationOfficials')" :src="require('@/assets/imgs/workbench/icon_delete.svg')" />
              </div>
            </div>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <div v-if="!disabled" class="footer">
      <el-checkbox v-model="checked">已阅读并同意</el-checkbox>
      <span
        v-for="(item, index) in protocol" :key="'prot-xz-'+index"
        @click="toRead(item)" class="accord">《{{ item.name }}》</span>
      <div @click="toJoin(1)" class="save">保存草稿</div>
      <div @click="toJoin(2)" class="sumbit">提交认证</div>
    </div>
    <!-- 小紫平台认证服务协议 -->
    <el-dialog :title="prot.name" :visible.sync="dialogVisible" width="70vw">
      <div style="height: 50vh;">
        <iframe :src="prot.url" ref="iframe" style="border:none" width="100%" height="100%"></iframe>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false" size="small" type="primary">关 闭</el-button>
      </span>
    </el-dialog>
    <verifition mode="pop"
      captchaType="clickWord"
      :imgSize="{ width: '330px', height: '155px' }"
      ref="verify"
      @success="handleCheckCodeSuccess"
    />
    <!-- 生成认证公函的元素 -->
    <div class="canvas_box" id="mycanvas" ref="mycanvas">
      <div class="container">
        <h2>授权公函</h2>
        <div>致：海南小紫医疗科技有限公司</div>
        <div>&emsp;&emsp;兹证明 {{ formData.name }}{{ formData.idCard ? `（${formData.idCard}）`: '' }} 为 {{ formData.tenantName }} 核心员工，负责我公司在“小紫平台”中相关业务工作，平台中所有操作行为均为我公司授权进行，由我公司承担一切责任。</div>
        <div>&emsp;&emsp;本授权长期有效，如有变更，我公司将在第一时间以书面方式通知贵公司。</div>
        <div>&emsp;&emsp;特此证明！</div>
        <div class="canvas-footer">
          <div>公司名称（盖章）：{{ formData.tenantName }}</div>
          <div>{{ dayjs().format('YYYY年MM月DD日') }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex';
import html2canvas from 'html2canvas';
import LoginApi from '@/api/login';
import SystemSetting from '@/api/systemSetting';
import { enterpriseList, companyDetail, authenticate } from '@/api/company';
import Verifition from '@/components/verifition';
import Upload from '@/components/common/upload';
import AreaCascaderMultiple from './Components/areaCascaderMultiple';
import dayjs from 'dayjs';
export default {
  components: {
    Verifition,
    Upload,
    AreaCascaderMultiple,
  },
  data () {
    var validateName = (rule, value, callback) => {
      const reg = /^[a-zA-Z\u4e00-\u9fa5]+$/;
      if (!value) {
        callback(new Error('请输入真实姓名'));
      } else if (!reg.test(value)) {
        callback(new Error('输入格式有误，请重新输入'));
      } else {
        callback();
      }
    };
    var validateCertCode = (rule, value, callback) => {
      const reg = /^\w\w\d{6}\w{9}\w$/;
      if (!value) {
        callback(new Error('请输入统一社会信用代码'));
      } else if (value.length !== 18 || !reg.test(value)) {
        callback(new Error('输入格式有误，请重新输入'));
      } else {
        callback();
      }
    };
    var validateIdCode = (rule, value, callback) => {
      const reg1 = /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$/;
      const reg2 = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])((\d{4})|\d{3}[A-Z])$/;
      if (!value) {
        callback(new Error('请输入身份证号码'));
      } else if (!reg1.test(value) && !reg2.test(value)) {
        callback(new Error('输入格式有误，请重新输入'));
      } else {
        callback();
      }
    };
    var validatePhoneNumber = (rule, value, callback) => {
      const reg = /^1[0-9]{10}$/;
      if (!value) {
        callback(new Error('请输入手机号'));
      } else if (!reg.test(value)) {
        callback(new Error('输入格式有误，请重新输入'));
      } else {
        callback();
      }
    };
    return {
      dayjs,
      checked: false,
      dialogVisible: false,
      disabled: false,
      loading: false,
      options: [],
      identify: {},
      formData: {
        authenticationType: '1',
        businessLicenses: [],
        businessAreaList: [],
        certCode: '',
        certificationOfficials: [],
        code: '',
        idCard: '',
        name: '',
        phoneNumber: '',
        tenantId: 0,
        tenantName: '',
        type: '',
      },
      rules: {
        tenantName: [
          { required: true, message: '请选择企业', trigger: 'blur' },
        ],
        name: [
          { required: true, message: '请输入真实姓名', trigger: 'blur' },
          { validator: validateName, trigger: 'blur' },
        ],
        businessAreaList: [
          { required: true, type: 'array', message: '请选择经营地区' },
        ],
        certCode: [
          { required: true, message: '请输入统一社会信用代码', trigger: 'blur' },
          { validator: validateCertCode, trigger: 'blur' },
        ],
        idCard: [
          { required: true, message: '请输入身份证号码', trigger: 'blur' },
          { validator: validateIdCode, trigger: 'blur' },
        ],
        phoneNumber: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { validator: validatePhoneNumber, trigger: 'blur' },
        ],
        businessLicenses: [
          { required: true, type: 'array', message: '请上传营业执照' },
        ],
        certificationOfficials: [
          { required: true, type: 'array', message: '请上传企业公函' },
        ],
        code: [
          { required: true, message: '请输入验证码', trigger: 'blur' },
        ],
      },
      phoneNumberCopy: '',
      prot: {},
      protocol: [
        {
          name: '小紫平台认证服务协议',
          url: this.sourceUrl + 'xzrzfw.html',
        },
      ],
      count: 0,
      again: false,
      timer: null,
      first: true,
      fileLists: [],
      fileLists2: [],
    };
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USERINFO',
    }),
    changeBusinessArea (val) {
      this.formData.businessAreaList = val;
      if (val.length) this.$refs['ruleForm'].clearValidate('businessAreaList');
    },
    saveImg (val) {
      let node = document.getElementById(val);
      let width = 595.28;
      let height = 841.89;
      let scale = 5;
      document.documentElement.scrollTop = 0;
      document.body.scrollTop = 0;
      html2canvas(node, {
        width: width,
        heigth: height,
        backgroundColor: '#ffffff',
        dpi: window.devicePixelRatio * scale,
        scale: scale,
        useCORS: true,
        allowTaint: true,
      }).then(canvas => {
        let url = canvas.toDataURL();
        let a = document.createElement('a');
        a.download = '企业公函';
        let event = new MouseEvent('click');
        a.href = url;
        a.dispatchEvent(event);
      });
    },
    handleRemove (file) {
      const index = this.fileLists.findIndex(v => v.uid === file.uid);
      this.fileLists.splice(index, 1);
      this.formData.businessLicenses.splice(index, 1);
    },
    handleRemove2 (file) {
      const index = this.fileLists2.findIndex(v => v.uid === file.uid);
      this.fileLists2.splice(index, 1);
      this.formData.certificationOfficials.splice(index, 1);
    },
    handleFileLists (file) {
      this.fileLists.push(file);
      this.formData.businessLicenses.push({
        businessLicenseName: file.name,
        businessLicenseUrl: file.url,
      });
    },
    handleFileLists2 (file) {
      this.fileLists2.push(file);
      this.formData.certificationOfficials.push({
        certificationOfficialName: file.name,
        certificationOfficialUrl: file.url,
      });
    },
    selectCompany (val) {
      if (typeof val === 'string') {
        this.formData.tenantName = val;
        this.formData.certCode = '';
      } else {
        this.formData.tenantName = val.name;
        this.formData.certCode = val.enterpriseCertCode;
      }
    },
    focusCompany () {
      this.remoteMethod(this.formData.tenantName);
    },
    uploadValidator (val) {
      return !!val.length;
    },
    getVericode () {
      const regEx = /^1[0-9]{10}$/;
      if (!regEx.test(this.formData.phoneNumber)) {
        this.$message({
          message: '请先输入正确的手机号码',
          type: 'warning',
        });
      } else {
        // 调校验码插件
        this.$refs.verify.show();
      }
    },
    scan (url) {
      window.open(url, '_blank');
    },
    deleteFile (i, obj) {
      this.formData[obj].splice(i, 1);
    },
    handleCheckCodeSuccess (captchaVO) {
      LoginApi.sendValidCode({captchaVO, phone: this.formData.phoneNumber, captchaType: 'AUTHENTICATION' }).then(() =>{
        if (!this.timer) {
          this.count = 120;
          this.timer = setInterval(() => {
            this.count--;
            if (this.count === 0) {
              clearInterval(this.timer);
              this.timer = null;
              this.again = true;
            }
          }, 1000);
        }
      });
    },
    toRead (item) {
      this.prot = item;
      // this.dialogVisible = true;
      window.open(item.url, 'blank');
    },
    toJoin (type) {
      if (!this.checked && type === 2) {
        this.$message({
          message: '请阅读并勾选服务条款',
          type: 'warning',
        });
        return;
      }

      if (type === 1) {
        this.save(type);
      } else {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            this.save(type);
          }
        });
      }
    },
    async save (type) {
      if (type === 2 && this.formData.phoneNumber && this.phoneNumberCopy !== this.formData.phoneNumber && !this.formData.code) {
        this.$message.error('更换手机号，请输入验证码');
        return;
      }
      this.fileLists = JSON.parse(JSON.stringify(this.fileLists));
      this.fileLists2 = JSON.parse(JSON.stringify(this.fileLists2));
      if (type === 2 && !this.fileLists.length) {
        this.$message.error('请上传营业执照');
        return;
      }
      if (type === 2 && !this.fileLists2.length) {
        this.$message.error('请上传认证公函');
        return;
      }
      await authenticate({
        ...this.formData,
        operateType: type,
        businessLicenses: this.fileLists.map(v => ({
          businessLicenseName: v.name,
          businessLicenseUrl: v.url,
        })),
        certificationOfficials: this.fileLists2.map(v => ({
          certificationOfficialName: v.name,
          certificationOfficialUrl: v.url,
        })),
      });

      this.$message.success(`认证信息已${type === 1 ? '保存草稿' : '提交审核'}`);
      if (type === 2) {
        this.update();
      }
      this.init();
      this.$tabs.items = [];
      this.$tabs.close({
        to: '/workbench',
        refresh: true,
      });
      window.location.reload();
      if (type === 1) {
        this.$router.back();
      }
    },
    async update () {
      let { employeeMsg } = JSON.parse(this.$local.get('userInfo'));
      const info = await SystemSetting.getDetailEmploy({
        findDepartmentFlag: true,
        findRoleFlag: true,
        id: employeeMsg.id,
      });
      let userInfo = info.body || {};
      this.$local.set('userInfo', userInfo);
      this.updateUserInfo(userInfo);
    },
    async remoteMethod (query) {
      if (query !== '') {
        this.loading = true;
        let data = await enterpriseList({
          keyWord: query,
          pageNum: 1,
          pageSize: 20,
          status: 'AUDIT_PASSED',
        });

        this.loading = false;
        this.options = data.body.list || [];

        if (this.first) {
          const reg = new RegExp(`^${query}$`);
          this.options = this.options.filter(v => reg.test(v.name));
          this.first = false;
          if (this.options.length) {
            this.formData.certCode = this.options[0].enterpriseCertCode;
          }
        }
      } else {
        this.options = [];
      }
    },
    async init (first = false) {
      this.disabled = false;
      this.identify = {};
      const userInfo = JSON.parse(this.$local.get('userInfo'));
      const data = await companyDetail({id: userInfo.tenantId});
      if (['1', '2'].includes(data.body.authenticationAuditStatus)) {
        this.disabled = true;
      }
      if (['1'].includes(data.body.authenticationAuditStatus)) {
        this.identify.title = '认证中，请耐心等待平台审核，审核时间：1-3个工作日！';
        this.identify.type = 'warning';
      }
      if (['2'].includes(data.body.authenticationAuditStatus)) {
        this.identify.title = '企业认证审核通过！';
        this.identify.type = 'success';
      }

      if (['3'].includes(data.body.authenticationAuditStatus)) {
        this.identify.title = `审核失败：${data.body.authenticationAuditRemark}`;
        this.identify.type = 'error';
      }

      this.formData.certCode = data.body.certCode;
      this.formData.businessAreaList = data.body.businessAreaList;
      this.formData.idCard = data.body.authenticationAdminIdcard;
      this.formData.name = data.body.authenticationAdminName || '';
      this.formData.phoneNumber = +(data.body.authenticationAdminPhone || userInfo.phoneNumber);
      this.phoneNumberCopy = +(data.body.authenticationAdminPhone || userInfo.phoneNumber);
      this.formData.tenantId = data.body.id;
      this.formData.tenantName = data.body.name;
      this.formData.businessLicenses = data.body.businessLicenses || [];
      this.formData.certificationOfficials = data.body.certificationOfficials || [];
      if (this.formData.businessLicenses.length) {
        this.formData.businessLicenses.map(item => {
          this.fileLists.push({
            name: item.businessLicenseName,
            url: item.businessLicenseUrl,
          });
        });
      }
      if (this.formData.certificationOfficials.length) {
        this.formData.certificationOfficials.map(item => {
          this.fileLists2.push({
            name: item.certificationOfficialName,
            url: item.certificationOfficialUrl,
          });
        });
      }
      if (first) {
        this.remoteMethod(this.formData.tenantName);
      }
    },
  },
  mounted () {
    this.init(true);
  },
};
</script>
<style lang="scss" scoped>
.identification {
  .status-tip {
    padding-bottom: 8px;
  }
  .content {
    margin-bottom: 75px;
    background-color: #F2F4F7;
    .panel {
      background-color: #ffffff;
      border-radius: 6px;
      margin-bottom: 8px;
      padding: 0 15px 15px;
      .title {
        font-size: 16px;
        font-weight: bold;
        padding-top: 15px;
        padding-bottom: 15px;
        display: flex;
        align-items: center;
        .tip-1 {
          padding-left: 15px;
          font-size: 12px;
          font-weight: 400;
          color: #929AA6;
          > b {
            cursor: pointer;
            color: #237FFA;
            text-decoration: underline;
            margin: 0 5px;
          }
        }
      }
      .col-box {
        display: flex;
      }
      .update {
        background: #E2F3FE;
        border-radius: 4px;
        padding: 0 12px;
        font-size: 14px;
        font-weight: 400;
        color: #237FFA;
        display: inline-flex;
        align-items: center;
        > img {
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
      }
      .tip {
        font-size: 12px;
        font-weight: 400;
        color: #5F6A7A;
        padding-top: 5px;
        padding-bottom: 10px;
      }
      .file {
        min-width: 360px;
        background: #F5F7FA;
        border-radius: 4px;
        padding: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        .left {
          font-size: 12px;
          font-weight: 400;
          color: #1F2733;
          padding-right: 30px;
        }
        .right {
          align-items: center;
          display: inline-flex;
          flex-shrink: 0;
          >img {
            margin-left: 8px;
            width: 16px;
            height: 16px;
            cursor: pointer;
          }
        }
      }
      .getCode {
        font-size: 12px;
        font-weight: 500;
        color: #237FFA;
        cursor: pointer;
      }
    }
  }
  .footer {
    position: fixed;
    background-color: #ffffff;
    bottom: 0;
    right: 0;
    width: calc(100% - 220px);
    padding: 15px 20px;
    text-align: right;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    .accord {
      color: #237FFA;
    }
    .save, .sumbit {
      margin-left: 10px;
      display: inline-block;
      background: #237FFA;
      padding: 5px 15px;
      border-radius: 4px;
      font-size: 14px;
      font-weight: 400;
      color: #FFFFFF;
    }
    .save {
      color: #1F2733;
      background: #F2F4F7;
    }
  }

  .canvas_box {
    position: fixed;
    bottom: 9999px;
    width: 595.28px;
    height: 841.89px;
    .container {
      background-color: #ffffff;
      padding: 30px;
      h2 {
        text-align: center;
        padding-bottom: 10px;
      }
      div {
        padding: 2px;
      }
      .tip {
        font-size: 16px;
        padding-top: 15px;
        padding-bottom: 30px;
      }
      .canvas-footer {
        text-align: right;
      }
    }
  }
}
</style>
