import { render, staticRenderFns } from "./identification.vue?vue&type=template&id=56ee0f0c&scoped=true"
import script from "./identification.vue?vue&type=script&lang=js"
export * from "./identification.vue?vue&type=script&lang=js"
import style0 from "./identification.vue?vue&type=style&index=0&id=56ee0f0c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ee0f0c",
  null
  
)

export default component.exports